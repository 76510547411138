.about-main {
    max-width: 1000px;
    margin: 4rem auto;
    padding: 0 4rem;
}

.about-banner {
    width: 100%;
    height: 60vh;
    background-color: var(--rose);
    display: flex;
    align-items: center;
    justify-content: center;
}
.about-banner-content {
    margin-top: 2%;
    width: 45%;
}
.about-banner-content div {
    margin-left: 5%;
    margin-right: 7%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: end;
}
.about-banner-content img {
    width: 25%;
    border-radius: 50%;
    object-fit: contain;
    float: right;
}
.about-banner-content .hr {
    background: linear-gradient(90deg, var(--rose) 0%, var(--gray) 25%, var(--gray) 75%, var(--rose) 100%);
}
.about-banner-content p {
    margin-left: 5%;
    font-size: 2rem;
}

.about-main h2 {
    font-size: 2.6rem;
    line-height: 1;
}
.about-main .p-holder {
    display: flex;
    flex-direction: column;
}
.about-main span {
    color: var(--rose);
}

.about-background {
    display: grid;
    grid-template-columns: 20% 1fr;
    align-items: start;
}
.about-background a, .about-background p {
    font-size: 2rem;
}
.about-background sup a {
    vertical-align: super;
    font-size: 1.6rem !important;
}
.about-background P:not(:first-child) {
    margin-top: 2rem;
}

.about-skills {
    margin-top: 8rem;

    font-size: 2rem;
    display: grid;
    grid-template-columns: 20% 1fr;
    align-items: start;
}

.about-featured-project {
    margin-top: 8rem;

    font-size: 2rem;
    display: grid;
    grid-template-columns: 20% 1fr;
    align-items: start;
}