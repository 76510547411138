.article-main {
    max-width: 1000px;
    margin: 0 auto 4rem;
    padding: 0 4rem;
}

.article-banner {
    width: 100%;
    height: 40vh;

    background-color: rgb(170, 187, 187);
}

.article-header-holder {
    height: 100%;
    
    display: grid;
    grid-template-columns: 1fr 80% 1fr;
}
.header-info-holder {
    background-color: var(--TRANS_black);
    border-radius: 8px;

    padding: 1rem;
    display: grid;
    grid-template-columns: 1fr 60% 1fr;
    grid-template-rows: 1fr 40% 1fr;
}
.header-info-holder p {
    color: var(--gray);
    font-size: 1.6rem;
}
.header-info-holder .article-title {
    grid-row: 2;
    grid-column: 1 / 4;
    justify-self: center;
    align-self: center;
}
.header-info-holder h2 {
    grid-row: 3;
    grid-column: 2;
    justify-self: center;
    align-self: first baseline;
    
    line-height: 1;
}
.header-info-holder .article-type {
    grid-row: 3;
    grid-column: 1;
    justify-self: start;
    align-self:last baseline;
}
.header-info-holder .article-date-posted {
    grid-row: 3;
    grid-column: 3;
    justify-self: end;
    align-self:last baseline;
}


.article-content-holder {
    margin-top: 4rem;
    font-size: 2rem;
}