.articles-main {
    max-width: 1000px;
    margin: 6rem auto 4rem;
    padding: 4rem;

    display: grid;
    grid-template-columns: 5% 5% 1fr;
    grid-row: calc(1fr * 6);
    row-gap: 20px;
}
.articles-main h1 {
    grid-row: 1;
    grid-column: 1 / 4;
}
.articles-main h2 {
    grid-row: 2;
    grid-column: 2 / 4;

    color: var(--gray);
}

.vr {
    margin-left: 4px;
    max-width: 3px;
    border-radius: 2px;
}
.articles-vr {
    grid-row: 2 / 7;
    grid-column: 1;
}
.recent-vr {
    grid-row: 3 / 7;
    grid-column: 2;
}


.article-card {
    background: linear-gradient(144deg, rgba(27,26,25,0.24) 0%, rgba(27,26,25,0.24) 35%, rgba(66, 0, 0, 0.1) 100%);
    max-height: 120px;
    border-radius: 9px;
    padding: 8px 12px;
    
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.article-card-text-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.article-card-text-content h2 {
    font-size: 2.8rem;
    color: var(--white) !important;
    line-height: 1;
    margin: 0;
}
.article-card-text-content div {
    display: flex;
    flex-direction: row;
}
.article-card-text-content div.icon {
    width: 20px;
    height: 20px;
    margin-right: 12px;
    margin-top: 2px;
    background-color: var(--gray);
}
.article-card-text-content div.update-icon {
    background-color: transparent;
    background-image: url(../../images/Icons/update_gray.svg);
}
.article-card-text-content div.info-icon {
    background-color: transparent;
    background-image: url(../../images/Icons/info_gray.svg);
}
.article-card-text-content div.plan-icon {
    background-color: transparent;
    background-image: url(../../images/Icons/plan_gray.svg);
}
.article-card-text-content div h3 {
    font-size: 2rem;
    text-transform: uppercase;
    color: var(--gray);
}