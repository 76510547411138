footer {
    background-color: rgba(0, 0, 0, 0.2);
    padding-top: 0.8rem;
    padding-bottom: 1rem;
}
footer p {
    font-size: 1.8rem !important;
    color: var(--gray);
}
footer h3 {
    color: var(--white);
    text-decoration: underline;
    text-decoration-color: var(--gray);
}
.footer-wrapper {
    max-width: 1440px;
    width: 100%;
    margin: 0 auto;

    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 20%;
    row-gap: 1rem;
}
.footer-wrapper .active a {
    color: var(--ACTIVE_rose);
}
.footer-link-holder {
    grid-column: 1;
    grid-row: 1;

    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 20% 1fr;
    height: fit-content;
}
.footer-contact-holder {
    grid-column: 2;
    grid-row: 1;
    justify-self: end;

    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 20% 1fr;
    height: fit-content;
}
.footer-contact-holder ul li a {
    font-size: 2.2rem;
}
.footer-wrapper .copyright-p {
    grid-column: 1;
    grid-row: 2;

    color: var(--gray);
}
.footer-legal-holder {
    grid-column: 2;
    grid-row: 2;
    justify-self: end;
    
    display: flex;
    flex-direction: row;
}
.footer-legal-holder > * {
    font-size: 2rem;
    line-height: 2rem;
    margin-left: 0.8rem
}