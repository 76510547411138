.contact-main {
    margin: var(--headerHeight) auto 0;
    padding: 0 4rem;
}

.contact-landing-splash {
    height: calc(100vh - var(--headerHeight));
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.contact-splash-title {
    width: 50%;
}
.contact-splash-title h1 {
    font-size: 10rem;
    margin-left: 16rem;
    text-decoration: underline;
}
.contact-splash-contact-info {
    width: 50%;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    gap: 24%;
}
.contact-splash-contact-info h2 {
    font-size: 7rem;
    line-height: 1.1;
    color: var(--ACTIVE_rose);
    text-decoration: underline;
    text-decoration-thickness: 2px;
}
.contact-splash-contact-info a {
    font-size: 3rem;
    margin-left: 4px;
}
.contact-splash-contact-info .contact-card {
    align-self: flex-end;
    text-align: end;
    margin-right: 32rem;
}