/* Global styles go here */
@font-face {
    font-family: 'jgs';
    src: url(./fonts/jgs_Font.ttf)
}

:root {
    --rose: #855a5c;
    --gray: #8a8e91; 
    --blue: #b8d4e3;
    --yellow: #e8c547;
    --black: #0e0c09;
    --white: #e1e1e1;

    --ACTIVE_rose: #87373b;
    --TRANS_black: rgba(0, 0, 0, 0.308);
    
    font-size: 62.5%;
    
    --headerHeight: 80px;
    background-color: var(--black);
}
body::after {  /* Used to preload images for transitions */
    position: absolute;
    width: 0;
    height: 0;
    overflow: hidden;
    z-index: -1;
    content: 
    url(./images/EnFLogo.svg)
    url(./images/EnFLogoActive.svg)

    url(./images/Icons/steam_rose.svg)
    url(./images/Icons/steam_rose_active.svg);
}


a {
    cursor: pointer;
    font-size: 2.4rem;
    line-height: 1.17;
    color: var(--rose);
    font-family: jgs, Arial, Helvetica, sans-serif;
    transition: all 0.4s ease 0s;
}
a:hover, a:focus {
    color: var(--white);
}
button {
    cursor: pointer;
    font-size: 2rem;
    line-height: 1.2;
    font-family: jgs, Arial, Helvetica, sans-serif;
    border-radius: 4px;
    background-color: var(--blue);
    color: var(--gray);
    padding: 0.3vw 0.5vw 0.1vw 0.4vw;
    transition: all 0.3s ease 0s;
}
button:hover, button:focus {
    background-color: var(--rose);
}
p {
    color: var(--white);
    font-family: jgs, Arial, Helvetica, sans-serif;
}
h1{
    font-size: 4.2rem;
    line-height: 1.85;
    color: var(--white);
    font-family: jgs, Arial, Helvetica, sans-serif;
    font-weight: 500;
}
h2 {
    font-size: 2.8rem;
    line-height: 1.75;
    color: var(--rose);
    font-family: jgs, Arial, Helvetica, sans-serif;
}
h3 {
    font-size: 2.4rem;
    line-height: 1.2;
    color: var(--rose);
    font-family: jgs, Arial, Helvetica, sans-serif;
}

main {
    margin: var(--headerHeight) 100px 0 100px;
    height: fit-content;
}
.hr {
    min-height: 1px;
    background: var(--rose);
    margin: 10px 0;
}
.vr {
    height: 100%;
    max-width: 1px;
    background: var(--rose);
}
.fake-header-box {
    width: 100%;
    height: var(--headerHeight);
    position: fixed;
    top: 0;
    left: 0;
    background-color: var(--black);
    box-shadow: 0 3px 2px -2px rgba(71, 71, 71, 0.281);
    z-index: 9;
}